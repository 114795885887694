import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const Vasco = lazy(() => import('sections/hero/Vasco.js'));
const Cita = lazy(() => import('sections/testimonials/Cita.js'));
const BoxCol3Conecta = lazy(() => import('sections/hero/BoxCol3Conecta.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {

    // Datos estructurados para preguntas y respuestas
    const Local = {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "VASCO Solutions",
      "url": "https://solucionesenlaweb.com",
      "logo": "https://solucionesenlaweb.com/img/vasco-solutions.webp",
      "image": "https://solucionesenlaweb.com/img/vasco-solutions.webp",
      "telephone": "+573102456789, +1-940-603-4343",
      "priceRange": "$$",  // Rango de precios, usa el símbolo de dólar para representar el rango
      "address": [
        {
          "@type": "PostalAddress",
          "streetAddress": "Santa Rosa de Cabal",
          "addressLocality": "Santa Rosa de Cabal",
          "addressRegion": "Risaralda",
          "postalCode": "661020",
          "addressCountry": "CO"
        },
        {
          "@type": "PostalAddress",
          "streetAddress": "Coral Gables",
          "addressLocality": "Miami",
          "addressRegion": "Florida",
          "postalCode": "33143",
          "addressCountry": "US"
        }
      ],
      "contactPoint": [
        {
          "@type": "ContactPoint",
          "contactType": "Customer Service",
          "telephone": "+1-954-603-4747",
          "email": "ventas@vascosolutions.com",
        },
        {
          "@type": "ContactPoint",
          "contactType": "Departamento de Ventas",
          "telephone": "+573102767675",
          "email": "ventas@vascosolutions.com",
        }
      ],
      "sameAs": [
        "https://es-la.facebook.com/VASCOSolutions",
        "https://x.com/VASCOSOLUTIONS"
      ]
    }


    return (
      <div>
        <Helmet>
          <title>Conecta con VASCO Solutions en Colombia y Estados Unidos</title>
          <meta name="description" content="Tu enlace con VASCO Solutions. Estamos disponibles para responder tus preguntas y atender tus necesidades. ¡Conecta con nosotros hoy mismo!" />
          <script type="application/ld+json">
            {JSON.stringify(Local)}
          </script>
        </Helmet>
        <Layout>
          <Vasco />
          <Cita />
          <BoxCol3Conecta />
          <Llamenos />
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`